import { Component, OnInit } from '@angular/core';
import { RfpMgmtApiService, RfpUser } from '@xpo-ltl/sdk-rfpmgmt';
import { ColDef, ColGroupDef, GridApi, GridOptions, GridReadyEvent, SelectionChangedEvent } from 'ag-grid-community';
import { GridColumnPartialsHelper } from 'src/app/_reusable/grid-column-partial-helper';
import { UserEditDialogComponent, UserEditDialogInput } from './user-edit-dialog/user-edit-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { GridService } from 'src/app/_services/grid.service';
import { XpoAgGridColumns } from '@xpo-ltl/ngx-ag-grid';
import { TeardownLogic } from 'rxjs';
import { UserBulkEditDialogComponent, UserBulkEditDialogInput } from './user-bulk-edit-dialog/user-bulk-edit-dialog.component';
import { UserService } from 'src/app/_services/user.service';

const adminColumns: string[] = [
  "roleOptions.manageUserRdsReview",
  "roleOptions.isAdmin",
  "isActive",
  "isDeleted",
  "applicationOptions.assignedPricingGroup"
]


@Component({
  selector: 'manager-dashboard',
  templateUrl: './manager-dashboard.component.html',
  styleUrls: ['./manager-dashboard.component.scss']
})
export class ManagerDashboardComponent implements OnInit {

  userGridColDefs: (ColDef | ColGroupDef)[] = [
    GridColumnPartialsHelper.SelectionWithSelectAll,
    GridColumnPartialsHelper.RowIndex,
    { field: "employeeId",              headerName: "Employee ID",    filter: 'agTextColumnFilter', hide: true, },
    { field: "employeeFullName",        headerName: "Employee Name",  filter: 'agTextColumnFilter', },
    { field: "managerEmployeeId",       headerName: "Manager ID",     filter: 'agTextColumnFilter', hide: true,},
    { field: "managerFullName",         headerName: "Manager Name",   filter: 'agTextColumnFilter', },
    { field: "regionCode",              headerName: "Region",         filter: 'agSetColumnFilter',  },
    { field: "roleType",                headerName: "Role",           filter: 'agSetColumnFilter',  },
    { field: "channelCode",             headerName: "Channel",        filter: 'agSetColumnFilter',  },
    { field: "effectiveDate",           headerName: "Effective Date", hide: true, ...GridColumnPartialsHelper.DateCell(),},
    { field: "expiryDate",              headerName: "Expiry  Date",   hide: true, ...GridColumnPartialsHelper.DateCell(),},
    {
      headerName: "Role Options",
      children: [
        { field: "roleOptions.isRds",               headerName: "RDS",                flex: 0,  width: 112, ...GridColumnPartialsHelper.BooleanCell(), },
        { field: "roleOptions.premiumServiceAE",    headerName: "PSAE",               flex: 0,  width: 112, ...GridColumnPartialsHelper.BooleanCell(), },
        { field: "roleOptions.isManager",           headerName: "Manager",            flex: 0,  width: 112, ...GridColumnPartialsHelper.BooleanCell(), },
        { field: "roleOptions.manageUserRdsReview", headerName: "Manage RDS Review",  flex: 0,  width: 170, ...GridColumnPartialsHelper.BooleanCell(), columnGroupShow: 'open', },
        { field: "roleOptions.isAdmin",             headerName: "Admin",              flex: 0,  width: 112, ...GridColumnPartialsHelper.BooleanCell(), columnGroupShow: 'open', },
        { field: "isActive",                        headerName: "Active",             flex: 0,  width: 112, ...GridColumnPartialsHelper.BooleanCell(), columnGroupShow: 'open', hide: true, },
        { field: "isDeleted",                       headerName: "Deleted",            flex: 0,  width: 112, ...GridColumnPartialsHelper.BooleanCell(), columnGroupShow: 'open', hide: true, },
      ]
    },
    {
      headerName: "Application Options",
      children: [
        { field: "applicationOptions.enablePremiumServices", headerName: "Premium Services",    flex: 0,  width: 112, ...GridColumnPartialsHelper.BooleanCell(), },
        { field: "applicationOptions.requireRdsReview",      headerName: "RDS Review",          flex: 0,  width: 121, ...GridColumnPartialsHelper.BooleanCell(), },
        { field: "applicationOptions.assignedPricingGroup",  headerName: "Pricing Group",       flex: 0,  width: 130, ...GridColumnPartialsHelper.BooleanCell(), columnGroupShow: 'open', },
        { field: "applicationOptions.cannotModifyRfp",       headerName: "Cannot Modify Rfps",  flex: 0,  width: 150, ...GridColumnPartialsHelper.BooleanCell(), columnGroupShow: 'open', },
      ]
    },
  ]

  gridApi: GridApi;
  gridLoadingData: boolean = false;
  allUsers: RfpUser[] = [];
  selectedUsers: RfpUser[] = [];

  gridOptions: GridOptions = {
    defaultColDef: {
      flex: 1,
      resizable: true,
      sortable: true,
      filter: true,
    },
    rowSelection: 'multiple',
    maintainColumnOrder: true,
    pagination: true,
    columnDefs: this.userGridColDefs,
    getRowNodeId: (data) => data.employeeId,
  }


  constructor(private rfpApi: RfpMgmtApiService, private userService: UserService, private dialog: MatDialog) { }

  ngOnInit(): void { }

  onGridReady(event: GridReadyEvent): void {
    this.gridApi = event.api;
    this.applyColumnVisibility();
    this.gridApi.addEventListener('selectionChanged', (xpoEvent: SelectionChangedEvent) => this.onSelectionChanged(xpoEvent));
    this.getGridData();
  } 

  private onSelectionChanged(event: SelectionChangedEvent): void {
    const gridApi = this.gridApi ?? event?.api;
    this.selectedUsers = gridApi.getSelectedRows();
  }

  applyColumnVisibility() {
    let colDefs: (ColDef | ColGroupDef)[] = this.userGridColDefs.deepCopy();

    this.userService.rfpUser$.subscribe({
      next: (user: RfpUser) => {
        if(!user.roleOptions.isAdmin){
          colDefs = GridService.removeColumnDefsById(colDefs, adminColumns)
        }

        this.gridApi.setColumnDefs(colDefs);
      }
    })
  }
  
  getGridData(){
    this.gridApi.showLoadingOverlay();
    this.gridLoadingData = true;

    this.rfpApi.listUsers({} as any).subscribe(users => {
      this.allUsers = users.items;
      this.gridApi.setRowData(users.items)
    }).add((teardown: TeardownLogic) => this.gridLoadingData = false)
  }

  refreshGridData(){
    this.gridApi.showLoadingOverlay();
    this.gridLoadingData = true;
    this.rfpApi.listUsers({} as any).subscribe(users => {
      this.allUsers = users.items;
      GridService.softRefreshGridData(this.gridApi, this.allUsers, "employeeId")
    }).add((teardown: TeardownLogic) => this.gridLoadingData = false)
  }

  openEditUserDialog(){
    if(this.selectedUsers.length == 1){
      const dialogData: UserEditDialogInput = {
        allUsers: this.allUsers,
        selectedUser: this.selectedUsers[0],
      }
  
      this.dialog.open(UserEditDialogComponent, { data: dialogData }).afterClosed().subscribe((updatedUser: RfpUser)  => {
        if(updatedUser){
          const rowNode = this.gridApi.getRowNode(updatedUser.employeeId);
          rowNode.setData(updatedUser);
          this.gridApi.deselectAll();
        }      
      })
    } else {
      const dialogData: UserBulkEditDialogInput = {
        selectedUsers: this.selectedUsers,
      }
  
      this.dialog.open(UserBulkEditDialogComponent, { data: dialogData }).afterClosed().subscribe((updatedUsers: RfpUser[])  => {
        if(updatedUsers){
          this.refreshGridData();
        }      
      })
    }
  }

  filtersExist(): boolean {
    return this.gridApi?.isAnyFilterPresent()
  }
  clearFilters(){
    this.gridApi.setFilterModel(null);
  }
}
